const metaTag = document.createElement("meta");
metaTag.name = "viewport";
metaTag.content = "user-scalable=0";
document.getElementsByTagName("head")[0].appendChild(metaTag);

const active = document.getElementsByClassName("window");

window.createWindow = function(id) {
    let windowID = document.getElementById("window" + id);
    let headerID = windowID.firstElementChild;
    headerID.id = "window" + id + "header";

    if (id != 5) {
        let createCloseButton = document.createElement("b");
        createCloseButton.id = "closeButton" + id;
        createCloseButton.innerHTML = "×";
        document.getElementById("window" + id + "header").appendChild(createCloseButton);

        document.getElementById("closeButton" + id).onclick = function () {
            $(".overlay").removeClass('overlay__visible');
            fadeOut(windowID);
            if (id == 1) {
                closeWindow(5);
            }
        };
    }
    dragElement(windowID);
}

window.closeWindow = function (id) {
    let windowID = document.getElementById("window" + id);
    fadeOut(windowID);
}

window.openWindow = function (id) {
    let windowID = document.getElementById("window" + id);

    $(".overlay").addClass('overlay__visible');

    fadeIn(windowID);
};

function dragElement(elmnt) {
    var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
    if ("ontouchstart" in document.documentElement) {
        var pos1touch = 0,
            pos2touch = 0,
            pos3touch = 0,
            pos4touch = 0;
    }
    if (document.getElementById(elmnt.id + "header")) {
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        document.getElementById(elmnt.id + "header").ontouchstart = dragMouseDown;
    }

    function dragMouseDown(e) {
        if (!"ontouchstart" in document.documentElement) {
            e.preventDefault();
        }
        pos3 = e.clientX;
        pos4 = e.clientY;
        if ("ontouchstart" in document.documentElement) {
            try {
                pos3touch = e.touches[0].clientX;
                pos4touch = e.touches[0].clientY;
            } catch(error) {}
        }
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
        document.ontouchend = closeDragElement;
        document.ontouchmove = elementDrag;

    }

    function elementDrag(e) {
        e.preventDefault();
        if ("ontouchstart" in document.documentElement) {
            pos1touch = pos3touch - e.touches[0].clientX;
            pos2touch = pos4touch - e.touches[0].clientY;
            pos3touch = e.touches[0].clientX;
            pos4touch = e.touches[0].clientY;
            elmnt.style.top = (elmnt.offsetTop - pos2touch) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1touch) + "px";
        } else {
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            if ((elmnt.offsetTop - pos2) > 20) {
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            }
            if ((elmnt.offsetLeft - pos1))  { // - (elmnt.offsetWidth/2) > 20) {
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }
        }
    }

    function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;

        $(".window").each(function() {
            if ($(this).offset().top < 0) {
                $(this).offset({ top: 10 });
            }
        });
    }
}

$( window ).resize(function() {
    $(".window").each(function() {
        if ($(this).offset().top < 0) {
            $(this).offset({ top: 10 });
        }
    });
});


function fadeIn(elmnt) {
    elmnt.style.opacity = 0;
    elmnt.classList.remove("hidden");

    if (elmnt.id == 'window1' || elmnt.id == 'window2') {
        elmnt.style.display = 'flex';
    } else {
        elmnt.style.display = 'initial';
    }
    if (elmnt.classList.contains("fade")) {
        var opacity = 0;
        var timer = setInterval(function () {
            opacity += 30 / 70;
            if (opacity >= 1) {
                clearInterval(timer);
                opacity = 1;
            }
            elmnt.style.opacity = opacity;

        }, 50);
    } else {
        elmnt.style.opacity = "1";
    }

    $("body").addClass('noscroll');
}

function fadeOut(elmnt) {

    if (elmnt != undefined && elmnt != null) {
        if (elmnt.classList.contains("fade")) {
            var opacity = 1;
            var timer = setInterval(function () {
                opacity -= 30 / 70;
                if (opacity <= 0) {
                    clearInterval(timer);
                    opacity = 0;
                    elmnt.style.display = "none";
                    elmnt.classList.add("hidden");
                }
                elmnt.style.opacity = opacity;
            }, 50);
        } else {
            elmnt.style.display = "none";

        }
    }
    $("body").removeClass('noscroll');
}

